/**
 * = Footers
 */

.footer{

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            &:hover {
                color: $footer-link-hover-color;
            }

            a {
                text-decoration: none;
                position: relative;
                display: block;
                color: $gray-700;

                &:hover {
                    color: $footer-link-hover-color;
                }
            }

            .btn {
                margin: 0;
            }
        }

        &.links-horizontal{
            &:first-child a{
                padding-left: 0;
            }

            &:last-child a{
                padding-right: 0;
            }

        }

        &.links-vertical{
            li{
                display: block;
                margin-left: -5px;
                margin-right: -5px;

                a{
                    padding: 5px;

                    &:hover{
                        color: inherit !important;
                    }
                }
            }
        }

        &.icon-box{
            i{
                line-height: 1.7
            }
        }
    }

    .social-buttons{
        a,
        .btn{
            margin-top: 5px;
            margin-bottom: 5px;
            padding: $padding-footer-sm $padding-footer;
        }
    }

    .footer-brand{
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        
        img.common {
            height: 40px;
        }
    
        img {
            height: 40px;
            width: 40px;
        }
        &:hover,
        &:focus{
            color: $black;
        }
    }
    .footer-language-link{
        font-size:$font-size-sm;
        i{
            font-size: 0.75rem;
        }
    }
    .copyright{
        font-size: $font-size-sm;
    }

    .pull-center{
        display: inline-block;
        float: none;
    }
}