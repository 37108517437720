.animated-bg {
    animation: slide 5s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #60dafb 50%, white 50%);
    bottom: 0;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
}

.animated-bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 6s;
}

.animated-bg3 {
    animation-duration: 7s;
}


@keyframes slide {
    0% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(25%);
    }
}